import React, { useState, useRef } from 'react'
import './App.css';
import group from './images/groupe-wallpaper.jpg'
import Header from './Components/Header'
import murder from './images/murder.png'
import braquage from './images/braquage.png'
import weapons from './images/weapons.png'
import kidnap from './images/kidnap.png'
import point from './images/point.png'

const App = () => {

  return (
    <div className="App">
      <div className="App-container">
        <div className="App-wrapper" id='accueil'>
          <Header />
          <h1 style={{ fontFamily: 'Rebelion', fontSize: '120px', padding: '19%' }}>Milieu</h1>
        </div>
        <div className="App-wrapper2" id='about_us'>
          <h2 style={{ fontSize: "32px", marginTop: "120px", fontWeight: 'bold' }}>Qui sommes nous ?</h2>
          <p style={{ width:'50%', margin: '4rem auto', lineHeight: '1.8', textAlign: 'justify' }}>Le Milieu, dit aussi haute-pègre (xixe siècle), Mitan (1920-1970 environ, de l'occitan Mitan, signifiant "milieu") ou grand banditisme (actuel), est l'appellation donnée au crime organisé en France. Abréviation utilisée pour la première fois par Francis Carco, dérivée de l'expression journalistique « un milieu très spécial »1. Il n'existe pas de Mafia française à proprement parler mais il existe différents gangs, réseaux... Le Milieu définit la pègre en France. L'expression est également utilisée en Italie comme en témoigne les films de la Trilogie du Milieu (Trilogia del milieu) sur la pègre milanaise.<br/><br/>Depuis les années 2000, le grand banditisme a adopté un nouveau visage, avec des parrains majoritairement issus des cités de banlieue. Cette nouvelle génération surarmée et très déterminée, a supplanté le « milieu » traditionnel des années 1970 et 1980.</p>
          <img src={group} style={{ width: '50%', borderRadius: '25px', marginBottom: '100px', border: "6px solid #91703D" }} />
        </div>
        <div className='app-banner'>
          <div style={{ display: 'flex', width: '50%', height: '100%', textAlign: 'center', flexDirection: 'column', margin: 'auto', justifyContent: 'center' }}>
            <h4 style={{ fontSize: '2.5rem', fontWeight: '400', marginBottom: '12px', lineHeight: '1.5', fontFamily: 'rebelion' }}>"Moi je n 'ai confiance qu 'en mon manche et ma parole : l&nbsp;'une est de fer et l 'autre d 'acier "</h4>
          </div>
        </div>
        <div className="App-wrapper2" id='what_we_do'>
          <h2 style={{ fontSize: "32px", marginTop: "120px", fontWeight: 'bold' }}>Que faisons-nous ?</h2>
          <div style={{ boxSizing: 'border-box',display: 'flex', width: '50%', height: '100%', textAlign: 'center', flexWrap: 'wrap', margin: 'auto', justifyContent: 'center', marginLeft: 'auto', marginRight: 'auto', marginBottom: '60px', marginTop: '50px' }}>
            <div style={{ width: '45%', display: 'flex', alignItems: 'center', flexDirection: 'column', padding: '10px 20px' }}>
              <img style={{ width: '180px', height: '180px', marginBottom: '30px' }} src={murder} />
              <p style={{ fontSize: '24px' }}>Homicides programés</p>
            </div>
            <div style={{ width: '45%', display: 'flex', alignItems: 'center', flexDirection: 'column', padding: '10px 20px' }}>
              <img style={{ width: '180px', height: '180px', marginBottom: '30px' }} src={braquage} />
              <p style={{ fontSize: '24px' }}>Braquages</p>
            </div>
            <div style={{ width: '45%', display: 'flex', alignItems: 'center', flexDirection: 'column', padding: '10px 20px' }}>
              <img style={{ width: '180px', height: '180px', marginBottom: '30px' }} src={point} />
              <p style={{ fontSize: '24px' }}>Ventes d'informations</p>
            </div>
            <div style={{ width: '45%', display: 'flex', alignItems: 'center', flexDirection: 'column', padding: '10px 20px' }}>
              <img style={{ width: '180px', height: '180px', marginBottom: '30px' }} src={kidnap} />
              <p style={{ fontSize: '24px' }}>Mercenaires</p>
            </div>
            <div style={{ width: '45%', display: 'flex', alignItems: 'center', flexDirection: 'column', padding: '10px 20px' }}>
              <img style={{ width: '180px', height: '180px', marginBottom: '30px' }} src={weapons} />
              <p style={{ fontSize: '24px' }}>Vente d'armes</p>
            </div>
          </div>
          <h2 id='contact_us' style={{ fontSize: '32px', fontWeight: 'bold', marginBottom: '20px' }}>Vous souhaitez utiliser un de ces services ?</h2>
          <h3 style={{ marginBottom: '20px' }}>Rejoignez nous et précisez votre demande</h3>
          <iframe style={{ margin: '40px 0' }} src="https://discord.com/widget?id=668986364435496976&theme=dark" width="350" height="500" allowtransparency="true" frameborder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
          <p style={{ fontWeight: 'bold', marginBottom: '40px' }}>Groupe faisant parti du serveur <a href='https://twitter.com/fraternitygta?lang=fr' target='_blank'>FRaternityRP</a></p>
        </div>
      </div>
    </div>
  );
}

export default App;
