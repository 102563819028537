import React, { useEffect, useState } from 'react'
import './Header.css'

import logo from '../images/logo.png'

const Header = () => {
    const [scrolling, setScrolling] = useState(false);
    const [scrollTop, setScrollTop] = useState(0);
    
    useEffect(() => {
        const onScroll = e => {
          setScrollTop(e.target.documentElement.scrollTop);
          setScrolling(e.target.documentElement.scrollTop > scrollTop);
        };
        window.addEventListener("scroll", onScroll);
        console.log(scrollTop)
        return () => window.removeEventListener("scroll", onScroll);
      }, [scrollTop]);

    return (
        <div className='animed-bg' style= {{ zIndex: '1', display: 'flex', width: '100%', height: '90px', alignItems: 'center', justifyContent: 'center', position: 'fixed', background: scrollTop > 10 ? '#1D1D1D' : '' }}>
            <img src={logo} style={{ height: '70px', width: '70px', position: 'absolute', left: '50px'  }} />
            <div className='header-container'>
                <ul>
                    <a href='#accueil'><li>Accueil</li></a>
                    <a href='#about_us'><li>Qui sommes-nous ?</li></a>
                    <a href='#what_we_do'><li>Que faisons nous ?</li></a>
                    <a href='#contact_us'><li>Nous contacter</li></a>
                    
                
                </ul>
            </div>
        </div>
    )
}

export default Header